<h2 mat-dialog-title>Add Level</h2>
<mat-dialog-content style="min-height: 100px;">

    <form [formGroup]="form">

        <div class="row">

            <div class="col-12 left-side-div">
                <div class="row">
                    <div class="col-4">
                        <label class="map-label">Map name from :</label>
                    </div>
                    <div class="col-8" style="display: grid;">
                        <!-- <input type="text" formControlName="map" class="form-control text"> -->
                        <mat-radio-group formControlName="textType" class="radio-btn">
                            <mat-radio-button *ngFor="let text of textTypes" [value]="text.value">
                                {{text.name}}
                            </mat-radio-button>
                        </mat-radio-group>
    
                        <mat-error label="Map Name From" formError [control]="form.get('textType')"
                            style="font-size: 12px;">
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="col-12 left-side-div">
                <div class="row">
                    <div class="col-4">
                        <label>Level name :</label>
                    </div>
                    <div class="col-8">
                        <!-- <input type="text" formControlName="name" class="form-control text"> -->

                        <div *ngIf="form.get('textType').value === 'TEXT'">
                            <input type="text" class="form-control text" #tagInput formControlName="name"
                                [matAutocomplete]="auto">

                            <mat-autocomplete id="autocomplete" #auto="matAutocomplete"
                                [displayWith]="displayFn.bind(this)">
                                <mat-option *ngFor="let data of textData; let i = index" [value]="data.parentId">
                                    <span>
                                        <!-- <mat-icon [svgIcon]="tag.type" class="primary-color"></mat-icon> -->
                                        {{data.parentId}} ( {{data.text}} )
                                    </span>
                                </mat-option>
                            </mat-autocomplete>
                        </div>

                        <div *ngIf="form.get('textType').value === 'TAG'">
                            <input type="text" class="form-control text" formControlName="name"
                                [matAutocomplete]="autoTag">
                            <mat-autocomplete id="autocomplete" #autoTag="matAutocomplete"
                                [displayWith]="displayFnTag.bind(this)">
                                <mat-option *ngFor="let data of textData; let i = index" [value]="data.parentId">
                                    <span>
                                        {{data.uniqueId | slice: 0 : data.uniqueId.indexOf("_")}} ( {{data.value}} )
                                    </span>
                                </mat-option>
                            </mat-autocomplete>
                        </div>

                        <mat-error label="Level" formError [control]="form.get('name')"></mat-error>
                    </div>
                </div>
            </div>

            <div class="col-12 left-side-div">
                <div class="row">
                    <div class="col-4">
                        <label>Copy From :</label>
                    </div>
                    <div class="col-8">
                        <mat-select formControlName="levelCopy" class="form-control">
                            <mat-option *ngFor="let level of levels" [value]="level.parentId">
                                {{level.text}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 20px;">
            <div class="col-12 save-div">
                <button mat-raised-button class="rounded save-btn" (click)="create()" [disabled]="createBtnDis">
                    Create Level
                </button>
            </div>
        </div>
    </form>

</mat-dialog-content>