import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { $REST_URI } from 'src/environments/environment';
import { AutocompleteTag } from 'src/app/core/model/question.model';
import { TagResponse } from 'src/app/modules/admin/tags/tags.component';


@Injectable({
  providedIn: 'root'
})
export class TagService {

  constructor(
    private http: HttpClient
  ) { }

  create(data: AutocompleteTag) {
    let params = new HttpParams();
    if (data) {
      Object.keys(data).forEach(key => {
        params = params.append(key, data[key]);
      });
    }
    return this.http.post(`${$REST_URI}/foundation/specificationTag`, params);
  }

  update(data: AutocompleteTag) {
    console.log("update tag",data);
    let params = new HttpParams();
    if (data) {
      Object.keys(data).forEach(key => {
        if(key !== 'isInSieveGame' && key !== 'isInBiblistAnalysis') {
          params = params.append(key, data[key]);
        }
      });
    }
    return this.http.put(`${$REST_URI}/foundation/specificationTag`, params);
  }

  changeOrderSpecificationTag(data: any) {
    // let params = new HttpParams();
    // if (data) {
    //   Object.keys(data).forEach(key => {
    //     params = params.append(key, data[key]);
    //   });
    // }
    return this.http.put(`${$REST_URI}/foundation/specificationTag/changeOrderSpecificationTag`, data);
  }

  translate(data: AutocompleteTag) {
    console.log("translate params", data);
    let params = new HttpParams();
    if (data) {
      Object.keys(data).forEach(key => {
        params = params.append(key, data[key]);
      });
    }
    console.log("translate params", params);
    return this.http.put(`${$REST_URI}/foundation/specificationTag/translate`, params);
  }

  get(query: TagsQuery) {
    let params = new HttpParams();
    if (query) {
      Object.keys(query).forEach(key => {
        params = params.append(key, query[key]);
      });
    }
    return this.http.get<TagResponse>(`${$REST_URI}/foundation/specificationTag`, { params });
  }

  inactiveSpecificationTag(data: any) {
    return this.http.put(`${$REST_URI}/foundation/specificationTag/inactiveSpecificationTag`, data);
  }

}

interface TagsQuery {
  language: string,
  value?: string,
  type?: string,
  parentId?: string,
  _id?: string
}