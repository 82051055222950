export const environment = {
    production: true,

    firebase: {
        apiKey: "AIzaSyCo-qfhnOL4oQkQbU9OksDvznb1Ljc2_RA",
        authDomain: "biblist-phase-2.firebaseapp.com",
        databaseURL: "https://biblist-phase-2.firebaseio.com",
        projectId: "biblist-phase-2",
        storageBucket: "biblist-phase-2.appspot.com",
        messagingSenderId: "190150982097",
        appId: "1:190150982097:web:991607bb3deccf3e4548f0"
    }

};

// export const $IP = 'biblist.ruahtech.com'; //192.168.0.104
// export const $PORT = '3000';
export const $IP = 'api-services.biblist.net'; //192.168.0.104
export const $PORT = '4000';
export const $DOMAIN = `${$IP}:${$PORT}`;
export const $URI = `https://${$IP}:${$PORT}`;
export const $REST_URI = `${$URI}/api`;
export const $REST_URI_COMMON = `${$URI}/api/common`;
export const $SOCKET_URI = `${$URI}`;
export const $PATH = {
    customerAvatar: `${$REST_URI}/customer/avatar`,
    employeeAvatar: `${$REST_URI}/employee/avatar`,
    asset: `${$REST_URI}/asset/file`
}
export const $SOCKET_OPTIONS = {
    path: '/socket',
    query: {
        auth_token: localStorage.getItem('auth-token')
    },
};
