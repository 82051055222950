import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SnackBarService } from '../../services/snackbar/snack-bar.service';
import { VirusFreeService } from '../../services/virus-free/virus-free.service';
import { $validators } from '../../values/validators.values';
import { DataService, Language } from '../../services/data/data.service';
import { TagService } from '../../services/tag/tag.service';
import { $APP_VALUES } from '../../values/app.values';

@Component({
  selector: 'app-duplicate-map',
  templateUrl: './duplicate-map.component.html',
  styleUrls: ['./duplicate-map.component.scss']
})
export class DuplicateMapComponent implements OnInit {

  form: FormGroup = new FormGroup({
    map: new FormControl('', [Validators.required]),
    mapId: new FormControl('', [Validators.required]),
    startDate: new FormControl('', [Validators.required]),
    endDate: new FormControl(null),
    textType: new FormControl('', [Validators.required]),
  })

  saveBtn: boolean = false;

  loading: boolean = false;

  newDate: Date = new Date();

  maps: any[] = [];
  allMaps: any[] = [];

  mapControl: FormControl = new FormControl('');

  alltextData: any[] = [];
  textData: any[] = [];

  languages: Language[] = [];

  textTypes: any[] = $APP_VALUES.textTypes

  @ViewChild('tagInput')
  tagInput: ElementRef<HTMLInputElement>;

  constructor(
    private router: Router,
    private virusFreeService: VirusFreeService,
    private snackBarService: SnackBarService,
    private dataService: DataService,
    private tagService: TagService,
    public dialogRef: MatDialogRef<DuplicateMapComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    console.log("data", data);
    this.languages = data.languages;
  }

  save() {
    console.log("save map");
    console.log("this.form", this.form);
    if (this.form.valid) {
      this.saveBtn = true;
      this.loading = true;
      this.virusFreeService.duplicateMap(this.form.getRawValue()).subscribe(res => {
        this.loading = false;
        this.saveBtn = false;
        console.log("save duplicate res", res);
        this.snackBarService.info("Map duplicated successfully");
        this.router.navigate([`/games/virus-free/map/${res._id}`]);
        this.dialogRef.close();
      }, err => {
        this.loading = false;
        this.saveBtn = false;
        this.snackBarService.error(err.error);
      })
    }
  }


  selected(event: any) {
    if (event?.option.value) {
      this.form.get('mapId').setValue(event.option.value);
      this.form.get('mapId').updateValueAndValidity();
    }
  }

  displayTextFn(data: string): string {
    console.log(data);
    return this.alltextData?.find((ele) => ele.parentId === data)?.text || '';
  }

  displayFn(data: string): string {
    console.log(data);
    return this.alltextData?.find((ele) => ele.parentId === data)?.text || '';
  }

  displayFnTag(data: string): string {
    console.log(data);
    return this.alltextData?.find((ele) => ele.parentId === data)?.value || '';
  }

  getAllText() {
    // console.log("lan",this.languages);
    this.alltextData = [];
    let filterData = { language: this.languages[0].name, disablePagination: true }
    this.dataService.getText(filterData).subscribe(res => {
      console.log("All Text", res);
      this.alltextData = res.texts;
      this.textData = res.texts;
    }, err => {
      this.snackBarService.error(err.error);
    });
  }

  getAllTag() {
    // console.log("lan",this.languages);
    this.alltextData = [];
    let filterData = { language: "ENGLISH" }
    this.tagService.get(filterData).subscribe(res => {
      console.log("All Tag Text", res);
      this.alltextData = res.specificationTagsList;
      this.textData = res.specificationTagsList;
    }, err => {
      this.snackBarService.error(err.error);
    });
  }

  validateTextCheck() {
    let control = this.form.get('map');
    console.log(control);
    let found = this.alltextData.find(ele => ele.parentId === control.value);

    if (!found) {
      control.setValidators([Validators.required, $validators.customError('Name not found', true)]);
    } else {
      control.setValidators([Validators.required]);
    }
    control.updateValueAndValidity({ emitEvent: false });
  }

  ngOnInit(): void {
    this.loading = true;

    this.form.get('textType').setValue('TEXT');
    this.form.get('textType').updateValueAndValidity();

    this.virusFreeService.getAllVirusFreeMaps().subscribe(res => {
      console.log("get all maps", res);
      this.allMaps = res.data;
      this.maps = this.allMaps;
      this.loading = false;
    });
    this.mapControl.valueChanges.subscribe(res => {
      if (res) {
        const filterValue = res.toLowerCase() || '';
        this.maps = this.allMaps.filter(maps => maps.map.text.toLowerCase().includes(filterValue));
      } else {
        this.maps = this.allMaps;
      }
    })

    this.getAllText();

    this.form.get('map').valueChanges.subscribe(res => {
      // console.log("res", res);
      if (res) {
        // console.log("alltextData", this.alltextData);
        const filterValue = res.toLowerCase() || '';

        this.textData = this.alltextData?.filter(option => (option?.parentId.toLowerCase().includes(filterValue) || option?.text?.toLowerCase().includes(filterValue) || option?.value?.toLowerCase().includes(filterValue) || option?.uniqueId.toLowerCase().includes(filterValue)));
      } else {
        this.textData = this.alltextData;
      }
      this.validateTextCheck()
    })

    this.form.get('textType').valueChanges.subscribe(data => {
      if(data) {
        if(data === 'TEXT') {
          this.getAllText();
        } else if(data === 'TAG') {
          this.getAllTag();
        }
      }
    });
  }

}
