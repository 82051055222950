import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusPipe } from './status/status.pipe';
import { RolePipe } from './role/role.pipe';
import { CurrencySymbolPipe } from './currency-symbol/currency-symbol.pipe';
import { StatusColorPipe } from './status-color/status-color.pipe';
import { TagsFilterPipe } from './tags-filter/tags-filter.pipe';
import { ClaimPipe } from './claim/claim.pipe';
import { CorrectOptionsPipe } from './correct-options/correct-options.pipe';
import { QuestionOptionPipe } from './question-option/question-option.pipe';
import { LanguagePipe } from './language/language.pipe';
import { TagsTypePipe } from './tagType/tags-type.pipe';
import { CategoryPipe } from './category/category.pipe';
import { LevelLabelPipe  } from './level-label/level-label.pipe';


@NgModule({
  declarations: [
    StatusPipe,
    RolePipe,
    CurrencySymbolPipe,
    StatusColorPipe,
    TagsFilterPipe,
    ClaimPipe,
    CorrectOptionsPipe,
    QuestionOptionPipe,
    LanguagePipe,
    TagsTypePipe,
    CategoryPipe,
    LevelLabelPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    StatusPipe,
    RolePipe,
    CurrencySymbolPipe,
    StatusColorPipe,
    TagsFilterPipe,
    ClaimPipe,
    CorrectOptionsPipe,
    QuestionOptionPipe,
    LanguagePipe,
    TagsTypePipe,
    CategoryPipe,
    LevelLabelPipe
  ]
})
export class PipesModule { }
