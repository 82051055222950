import { Directive, Input, ElementRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[formError]'
})
export class ErrorDirective {

  @Input('control')
  formControl: AbstractControl;

  @Input('label')
  label: string

  @Input('patternMessage')
  _patternMsg: string;

  _message: string;
  private _validated: boolean;

  constructor(
    private eleRef: ElementRef,
  ) { }

  _getErrorMessage(error: string): string {
    switch (error) {
      case 'required':
        return `<b>${this.label || 'This field'}</b> is required`;
      case 'pattern':
        return `${this._patternMsg || 'This field is invalid'}`;
      case 'email':
        return `Please enter a valid <b>E-mail</b> address`;
      case 'minlength':
        return `<b>${this.label || 'This field'}</b> should be minimum ${this.formControl.errors[error].requiredLength} charecters`;
      case 'maxlength':
        return `<b>${this.label || 'This field'}</b> cannot exceed ${this.formControl.errors[error].requiredLength} charecters`;
      case 'min':
        return `<b>${this.label || 'This field'}</b> should be minimum ${this.formControl.errors[error].min}`;
      case 'max':
        return `<b>${this.label || 'This field'}</b> cannot exceed ${this.formControl.errors[error].max}`;
      case 'password':
        return `<b>Password</b> should be minimum 6 characters with at least one upper case and lower case character`;
      case 'confirmPassword':
        return `<b>Confirm Password</b> should match <b>Password</b>`;
      case 'customError':
        return `${this.formControl.errors['customError'].message}`;
      case 'contactNumber':
        return `Enter valid <b>Contact Number</b>`;
      case 'questionOption':
        return `Correct option should have at least one reference tag`;
      case 'questionAndOptions':
        return `Question text and Options text must be filled to generate audio`;
      case 'questionWrongOption':
        return `Wrong option should have at least one reference tag`;
      default:
        return `This field is invalid`;
    }
  }

  setError() {
    if (this.formControl.errors) {
      let temp = Object.keys(this.formControl.errors);
      (this.eleRef.nativeElement as HTMLElement).innerHTML = this._getErrorMessage(temp[0]);
    }
  }

  removeError() {
    (this.eleRef.nativeElement as HTMLElement).innerHTML = null;
  }

  ngOnInit() {

    this.formControl.statusChanges.subscribe((ele) => {
      if (this.formControl.status === 'INVALID' && this.formControl.touched && this.formControl.errors) {
        this.setError();
      } else {
        this.removeError();
      }
    });

  }

  ngAfterViewInit(): void {
    (this.eleRef.nativeElement as HTMLElement).classList.add('error');
  }

  ngAfterViewChecked(): void {
    if (this.formControl.touched && !this._validated) {
      this._validated = true;
      this.setError();
    }
  }

}
