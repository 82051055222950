import { Component } from '@angular/core';
import { IconRegisterService } from './core/services/icon-register/icon-register.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'biblist-app';

  constructor(
    private iconRegister: IconRegisterService
  ) {
    this.iconRegister.registerIcons();
  }
}
