import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class InitializerService {

  constructor(
    private userService: UserService,
    private fireAuth: AngularFireAuth,
    private firestore: AngularFirestore
  ) { }

  init() {
    var initPromise: Promise<boolean>[] = [];

    initPromise.push(new Promise((resolve, reject) => {
      this.fireAuth.user.subscribe(currentUser => {
        if (currentUser) {
          let role = [];
          this.userService.get(role, currentUser.uid).subscribe(res => {
            if (res) {
              this.userService._currentUser.next(res[0])
            }
            resolve();
          },error => {
            // this.snackBar.info(error.error);
            resolve();
          });
        } else resolve()
      });
    }));

    // initPromise.push(new Promise((resolve, reject) => {

    //   const collection = this.firestore.collection('users');

    //   collection.get().subscribe(res => {

    //     let users = [];

    //     res.forEach(user => {

    //       users.push({
    //         id: user.id,
    //         data: user.data()
    //       });

    //     });

    //     var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(users));
    //     var dlAnchorElem = document.createElement('a');
    //     dlAnchorElem.setAttribute("href", dataStr);
    //     dlAnchorElem.setAttribute("download", "scene.json");
    //     dlAnchorElem.click();

    //   });

    // }));

    return Promise.all(initPromise);
  }
}

export function initProviderFactory(provider: InitializerService) {
  return () => provider.init();
}
