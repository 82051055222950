<h2 mat-dialog-title>Crop Image</h2>
<mat-dialog-content>
    <div class="cropper-container">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
            [aspectRatio]="options.aspectRatio" [resizeToHeight]="options.resizeToHeight"
            [imageQuality]="options.imageQuality" [resizeToWidth]="options.resizeToWidth" format="jpeg"
            (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()">
        </image-cropper>
        <div class="loader-container" *ngIf="loading">
            <mat-spinner [strokeWidth]="5" mode="indeterminate"></mat-spinner>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" (click)="crop()">Crop</button>
    <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>