<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<h2 mat-dialog-title>Duplicate Map</h2>
<mat-dialog-content style="min-height: 170px;">

    <form [formGroup]="form">

        <div class="row">
            <div class="col-12 left-side-div">
                <div class="row" style="margin-top: 5px;">
                    <div class="col-4 label-div">
                        <label class="map-label">Map name from :</label>
                    </div>
                    <div class="col-8">
                        <!-- <input type="text" formControlName="map" class="form-control text"> -->
                        <mat-radio-group formControlName="textType" class="radio-btn">
                            <mat-radio-button *ngFor="let text of textTypes" [value]="text.value">
                                {{text.name}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <mat-error label="Map Name From" formError [control]="form.get('textType')"
                            style="font-size: 12px;">
                        </mat-error>
                    </div>
                </div>

                <div class="row">
                    <div class="col-4 label-div">
                        <label class="map-label">Map :</label>
                    </div>
                    <div class="col-8">
                        <div *ngIf="form.get('textType').value === 'TEXT'">
                            <input type="text" class="form-control text" #tagInput formControlName="map"
                                [matAutocomplete]="auto">

                            <mat-autocomplete id="autocomplete" #auto="matAutocomplete"
                                [displayWith]="displayFn.bind(this)">
                                <mat-option *ngFor="let data of textData; let i = index" [value]="data.parentId">
                                    <span>
                                        <!-- <mat-icon [svgIcon]="tag.type" class="primary-color"></mat-icon> -->
                                        {{data.parentId}} ( {{data.text}} )
                                    </span>
                                </mat-option>
                            </mat-autocomplete>
                        </div>

                        <div *ngIf="form.get('textType').value === 'TAG'">
                            <input type="text" class="form-control text" formControlName="map"
                                [matAutocomplete]="autoTag">
                            <mat-autocomplete id="autocomplete" #autoTag="matAutocomplete"
                                [displayWith]="displayFnTag.bind(this)">
                                <mat-option *ngFor="let data of textData; let i = index" [value]="data.parentId">
                                    <span>
                                        {{data.uniqueId | slice: 0 : data.uniqueId.indexOf("_")}} ( {{data.value}} )
                                    </span>
                                </mat-option>
                            </mat-autocomplete>
                        </div>

                        <mat-error label="Map Name" formError [control]="form.get('map')" style="font-size: 12px;">
                        </mat-error>
                    </div>
                </div>

                <div class="row">
                    <div class="col-4 label-div">
                        <label class="map-label">Duplicate From :</label>
                    </div>
                    <div class="col-8">
                        <!-- <input type="text" formControlName="mapId" class="form-control text"> -->
                        <input placeholder="Select Map Here..." class="form-control text" #mapInput
                            [formControl]="mapControl" [matAutocomplete]="auto">

                        <mat-autocomplete id="autocomplete" #auto="matAutocomplete" (optionSelected)="selected($event)"
                            [displayWith]="displayFn.bind(this)">
                            <mat-option *ngFor="let map of maps; let i = index" [value]="map._id">
                                <span>
                                    <!-- <mat-icon [svgIcon]="tag.type" class="primary-color"></mat-icon> -->
                                    {{map.map.text}}
                                </span>
                            </mat-option>
                        </mat-autocomplete>

                        <mat-error label="Duplicate From" formError [control]="form.get('mapId')"
                            style="font-size: 12px;">
                        </mat-error>
                    </div>
                </div>

                <div class="row">
                    <div class="form_date_inputs col-12">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-4 label-div">
                                    <label class="map-label">Start date :</label>
                                </div>
                                <div class="col-5">
                                    <div class="date-range-input">
                                        <input matInput [matDatepicker]="picker" formControlName="startDate"
                                            placeholder="Start date" [min]="newDate">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </div>

                                    <mat-error label="Start date" formError [control]="form.get('startDate')"
                                        style="font-size: 12px; margin-left: 24px;">
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form_date_inputs col-12" style="margin-top: 10px;">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-4 label-div">
                                    <label class="map-label">End date :</label>
                                </div>
                                <div class="col-5">
                                    <div class="date-range-input">
                                        <input matInput [matDatepicker]="picker1" formControlName="endDate"
                                            placeholder="End date" [min]="form.get('startDate').value">
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="row" style="margin-top: 20px;">
            <div class="col-12 save-div">
                <button mat-raised-button class="rounded save-btn" (click)="save()" [disabled]="saveBtn">
                    Duplicate map
                </button>
            </div>
        </div>

    </form>

</mat-dialog-content>