import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { $REST_URI } from 'src/environments/environment';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { BibleReference, SpecificationCategory } from '../../model/question.model';
import { $APP_VALUES } from '../../values/app.values';
import { TagResponse } from 'src/app/modules/admin/tags/tags.component';
import { map } from 'rxjs/operators';
import { TextResponse } from 'src/app/modules/admin/text-bank/text-bank.component';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private http: HttpClient
  ) { }

  getSpecificationTagTypes(language: string) {
    return this.http.get<any[]>(`${$REST_URI}/foundation/specificationTag/type?language=${language}`);
  }

  getLanguages() {
    return of($APP_VALUES.languages);
  }

  getVerse(query: BibleReference, language: string) {

    if (!query.toVerse) {
      delete query.toVerse;
    }

    let params = new HttpParams();
    Object.keys(query).forEach(key => {
      params = params.append(key, query[key]);
    });

    params = params.append('language', language);

    return this.http.get(`${$REST_URI}/foundation/bibleText`, { params });
  }

  getGoals() {
    return this.http.get<Bible[]>(`${$REST_URI}/foundation/bible`);
  }

  updateGoal(_id, goal) {
    return this.http.put(`${$REST_URI}/foundation/bible`, { _id, goal });
  }

  getTags(query: TagsQuery) {
    console.log(query)
    let params = new HttpParams();
    if (query) {
      Object.keys(query).forEach(key => {
        params = params.append(key, query[key]);
      });
    }

    return this.http.get<TagResponse>(`${$REST_URI}/foundation/specificationTag`, { params });
  }

  getAllTags(query?: any) {
    let params = new HttpParams();
    if (query) {
      Object.keys(query).forEach(key => {
        if (key === 'sort') {
          let sortKey = Object.keys(query[key])[0];
          params = params.append(sortKey, query[key][sortKey]);
        } else {
          params = params.append(key, query[key]);
        }
      });
    }
    console.log("params", params);
    return this.http.get<TagResponse>(`${$REST_URI}/foundation/specificationTag`, { params });
  }

  getAllTextTags(query?: any) {
    let params = new HttpParams();
    if (query) {
      Object.keys(query).forEach(key => {
        if (key === 'sort') {
          let sortKey = Object.keys(query[key])[0];
          params = params.append(sortKey, query[key][sortKey]);
        } else {
          params = params.append(key, query[key]);
        }
      });
    }
    console.log("params", params);
    return this.http.get<any>(`${$REST_URI}/foundation/textTag`, { params });
  }

  getNotification(query?: any) {
    let params = new HttpParams();
    if (query) {
      Object.keys(query).forEach(key => {
        if (key === 'sort') {
          let sortKey = Object.keys(query[key])[0];
          if(query[key][sortKey] && query[key][sortKey].length !== 0) {
            params = params.append(sortKey, query[key][sortKey]);
          }
        } else {
          if(query[key] && query[key].length !== 0) {
            params = params.append(key, query[key]);
          }
        }
      });
    }
    return this.http.get<any>(`${$REST_URI}/notification`, { params });
  }

  updateTag(_id, isInSieveGame) {
    return this.http.put<unknown>(`${$REST_URI}/foundation/specificationTag/isInSieveGame`, { _id, isInSieveGame });
  }

  getBible() {
    return this.http.get<Bible[]>(`/assets/json/bible.json`);
  }

  uploadAllLanguage(file: File) {

    let form = new FormData()
    form.append('excelfile', file);

    return this.http.post(`${$REST_URI}/foundation/appLanguage`, form, { responseType: 'arraybuffer' });
  }

  uploadAppLanguage(file: File) {

    let form = new FormData()
    form.append('excelfile', file);

    return this.http.post(`${$REST_URI}/foundation/appLanguage/upload`, form, { responseType: 'arraybuffer' });
  }

  downloadAppLanguage() {
    return this.http.get<any>(`${$REST_URI}/foundation/appLanguage/download`);
  }

  uploadBibleReference(file: File) {

    let form = new FormData()
    form.append('excelfile', file);

    return this.http.post(`${$REST_URI}/foundation/bibleText`, form, { responseType: 'arraybuffer' });
  }

  uploadBibleText(file: File) {

    let form = new FormData()
    form.append('excelfile', file);

    return this.http.post(`${$REST_URI}/foundation/bibleText/upload`, form, { responseType: 'arraybuffer' });
  }

  downloadBibleReference() {
    return this.http.get<any>(`${$REST_URI}/foundation/bibleText/download`);
  }

  getLanguage() {
    return this.http.get<Language[]>(`${$REST_URI}/foundation/language`);
  }

  uploadBackgroundImg(file: any) {
    let formData = new FormData();
    formData.append('backgroundImage', file);
    return this.http.post(`${$REST_URI}/game/virusfree/virusfreemap/backgroundImage`, formData);
  }

  getGamesList() {
    return this.http.get<any[]>(`${$REST_URI}/game/dashboard`);
  }

  addText(data: any) {
    return this.http.post(`${$REST_URI}/foundation/Text`, data);
  }

  getText(query: any) {
    let params = new HttpParams();
    if (query) {
      Object.keys(query).forEach(key => {
        if (key === 'sort') {
          let sortKey = Object.keys(query[key])[0];
          if(query[key][sortKey] && query[key][sortKey].length !== 0) {
            params = params.append(sortKey, query[key][sortKey]);
          }
        } else {
          if(query[key] && query[key].length !== 0) {
            params = params.append(key, query[key]);
          }
        }
      });
    }

    return this.http.get<TextResponse>(`${$REST_URI}/foundation/text`, { params });
  }

  updateText(id: string, data: any) {
    return this.http.put<any>(`${$REST_URI}/foundation/Text/${id}`, data);
  }

  translateText(data: any) {
    return this.http.post<any>(`${$REST_URI}/foundation/Text/translate`, data);
  }

  getTextById(id: any) {
    return this.http.get<any>(`${$REST_URI}/foundation/text/${id}`);
  }

  getTextImage(id: any) {
    return this.http.get<any>(`${$REST_URI}/foundation/text/preview/${id}`);
  }

  uploadTextImg(file: any) {
    let formData = new FormData();
    formData.append('preview', file);
    return this.http.post(`${$REST_URI}/foundation/text/preview`, formData);
  }

  uploadNotificationIcon(file: any) {
    let formData = new FormData();
    formData.append('icon', file);
    return this.http.post(`${$REST_URI}/notification/icon`, formData);
  }

  getNotificationById(id: any) {
    return this.http.get<any>(`${$REST_URI}/notification/${id}`);
  }

  createTextTag(data: any) {
    return this.http.post<any>(`${$REST_URI}/foundation/textTag`, data);
  }

  createNotification(data: any) {
    return this.http.post<any>(`${$REST_URI}/notification`, data);
  }

  updateNotification(id: string, data: any) {
    return this.http.put<any>(`${$REST_URI}/notification/${id}`, data);
  }

  translateNotification(data: any) {
    return this.http.post<any>(`${$REST_URI}/notification/translate`, data);
  }

  sendNotification(data: any) {
    return this.http.post<any>(`${$REST_URI}/notification/send`, data);
  }

  deleteNotification(id: string) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {}
    }

    return this.http.delete<any>(`${$REST_URI}/notification/${id}`, options);
  }

  duplicateNotification(data: any) {
    return this.http.post<any>(`${$REST_URI}/notification/duplicate`, data);
  }

  activeInactiveDelete(data: any) {
    return this.http.put<any>(`${$REST_URI}/game/common/presetSieve/activeInactiveDelete`, data);
  }

  getBibleVerse(query?: any) {
    let params = new HttpParams();
    if (query) {
      Object.keys(query).forEach(key => {
        if (key === 'sort') {
          let sortKey = Object.keys(query[key])[0];
          params = params.append(sortKey, query[key][sortKey]);
        } else {
          if(query[key]) {
            params = params.append(key, query[key]);
          }
        }
      });
    }
    console.log("params", params);
    return this.http.get<any[]>(`${$REST_URI}/game/scripture-run/bible-verse/admin`, { params });
  }

  getBibleVersion(data: any) {
    let params = new HttpParams();
    if (data) {
      Object.keys(data).forEach(key => {
        params = params.append(key, data[key]);
      });
    }
    return this.http.get<any>(`${$REST_URI}/foundation/bibleVersion`, { params });
  }

  getScriptureRunLeaderBoard(query?: any) {
    let params = new HttpParams();
    if (query) {
      Object.keys(query).forEach(key => {
        if (key === 'sort') {
          let sortKey = Object.keys(query[key])[0];
          params = params.append(sortKey, query[key][sortKey]);
        } else {
          if(query[key]) {
            params = params.append(key, query[key]);
          }
        }
      });
    }
    console.log("params", params);
    return this.http.get<any[]>(`${$REST_URI}/game/scripture-run/leaderBoard/admin`, { params });
  }

  getScriptureRunVerseDetails(query?: any) {
    let params = new HttpParams();
    if (query) {
      Object.keys(query).forEach(key => {
        if (key === 'sort') {
          let sortKey = Object.keys(query[key])[0];
          params = params.append(sortKey, query[key][sortKey]);
        } else {
          if(query[key]) {
            params = params.append(key, query[key]);
          }
        }
      });
    }
    console.log("params", params);
    return this.http.get<any[]>(`${$REST_URI}/game/scripture-run/bible-verse/admin/details`, { params });
  }
}

export interface Bible {
  bookName: string,
  chaptersCount: number,
  maxVerses: number[]
  osisCode: string,
  goal: number,
  sNo: number,
  _id: string;
}

interface TagsQuery {
  language: string,
  value?: string,
  type?: string,
  parentId?: string,
  _id?: string,
  tagType?: string,
}

export interface Topic {
  _id: string
  creationDate: Date | string
  language: string
  type: string
  value: string
  parentId?: string
}

export interface Language {
  code: string,
  googleApiLanguageCode?: string,
  name: string
}