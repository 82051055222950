import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppCommonModule } from '../../shared/app-common/app-common.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddMapDialogComponent } from '../add-map/add-map-dialog.component';
import { BrowserModule } from '@angular/platform-browser';
import { CropperDialogComponent } from '../image-cropper/cropper-dialog/cropper-dialog.component';
import { DirectivesModule } from '../../directives/directives.module';


@NgModule({
  declarations: [AddMapDialogComponent],
  imports: [
    CommonModule,
    AppCommonModule,
    // BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    DirectivesModule,
  ],
  exports: [
    AddMapDialogComponent
  ],
  entryComponents: [
    AddMapDialogComponent,
    CropperDialogComponent
  ],
})
export class AddMapDialogModule { }