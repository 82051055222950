import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';

export interface ImageCropperOptions {
  aspectRatio: number;
  resizeToHeight: number;
  resizeToWidth: number;
  imageQuality: number;
}

@Component({
  selector: 'app-cropper-dialog',
  templateUrl: './cropper-dialog.component.html',
  styleUrls: ['./cropper-dialog.component.scss']
})
export class CropperDialogComponent implements OnInit {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  loading: boolean = true;

  options: ImageCropperOptions;

  constructor(
    public dialogRef: MatDialogRef<CropperDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: { event: ImageCroppedEvent, options: ImageCropperOptions }
  ) {
    this.imageChangedEvent = data.event;
    this.options = data.options;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper

  }

  urltoFile(url, filename, mimeType) {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  crop() {
    const imageName = this.imageChangedEvent.target.files[0].name;
    const contentType = this.imageChangedEvent.target.files[0].type;
    const self = this;
    this.urltoFile(this.croppedImage, imageName, contentType)
      .then(function (file) {
        self.dialogRef.close({ file, base64: self.croppedImage });
      });
  }

  //Usage example:
  cropperReady() {
    // cropper ready
    this.loading = false;
  }
  loadImageFailed() {
    // show message
  }

  ngOnInit() {
  }

}

export interface FileCropperResponse {
  file: File,
  base64: string
}