import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CurrentUser, User } from '../../model/user.model';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { $REST_URI } from 'src/environments/environment';
import * as firebase from 'firebase/app';

import { tokenGetter } from '../../helpers/tokenGetter.helper';
import { Biblist } from '../../model/biblist.model';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  currentUser: Observable<CurrentUser>;
  _currentUser: BehaviorSubject<CurrentUser>;

  constructor(
    private http: HttpClient,

  ) {
    this._currentUser = new BehaviorSubject<CurrentUser>(null);
    this.currentUser = this._currentUser.asObservable();
  }

  setUser(data: CurrentUser) {
    this._currentUser.next(data);
    localStorage.setItem('currentUser', JSON.stringify(data));
  }

  forgotPassword(data) {
    return this.http.put(`${$REST_URI}/user/password/forgot`, data);
  }

  resetPassword(data, token: string) {
    let headers = new HttpHeaders();
    headers = headers.set('x-auth-token', token);
    console.log(headers.get('x-auth-token'));
    return this.http.put(`${$REST_URI}/user/password/reset`, data, { headers });
  }

  getUser(): CurrentUser | null {
    const temp = localStorage.getItem('currentUser');
    if (temp) {
      const currentUser: CurrentUser = JSON.parse(temp);
      return currentUser;
    } else return null;
  }

  saveUser(data: CurrentUser, token: String) {
    if (data) {
      this.setUser(data);
    }
    if (token) this.setAuthToken(token);
  }

  removeUser() {
    this.setUser(undefined);
    localStorage.removeItem('currentUser');
    this.removeToken();
  }

  removeAccess(data) {
    return this.http.put(`${$REST_URI}/user/remove`, data);
  }

  setAuthToken(token: any) {
    if (!!token && typeof token === 'string') {
      localStorage.setItem('auth-token', token);
    } else {
      throw new Error(`NO token or malformed, Token: ${token}.`)
    }
  }

  getAuthToken() {
    return tokenGetter();
  }

  removeToken() {
    localStorage.removeItem('auth-token');
  }

  // isAuthenticated() {
  //   var token = this.getAuthToken();
  //   if (token) {
  //     let expired: boolean = this.jwtHelper.isTokenExpired(token);
  //     if (expired) {
  //       console.log('*************  TOKEN EXPIRED  *************')
  //       localStorage.removeItem('auth-token');
  //       return false;
  //     }
  //     return true;
  //   } else
  //     return false;
  // }

  uploadAvatar(file) {
    let formData = new FormData();
    formData.append('avatar', file);
    return this.http.post(`${$REST_URI}/user/avatar`, formData);
  }

  downloadAvatar(id) {
    return this.http.get(`${$REST_URI}/client/avatar/${id}`);
  }

  updateProfile(data: User) {
    return this.http.put(`${$REST_URI}/client`, data);
  }

  getAdminUsers(query: any) {
    let params = new HttpParams();

    if (query) {
      Object.keys(query).forEach(key => {
        if (key === 'sort') {
          let sortKey = Object.keys(query[key])[0];
          params = params.append(sortKey, query[key][sortKey]);
        } else {
          if (query[key]) {
            if (typeof (query[key]) === 'string' || (query[key] instanceof Date) || typeof (query[key]) === 'number' || typeof (query[key]) === 'boolean') {
              params = params.append(key, query[key]);
            } else {
              if (query[key].length > 0) {
                params = params.append(key, query[key]);
              }
            }
          }
        }
      });
    }
    
    return this.http.get<User[]>(`${$REST_URI}/user`, { params });
  }

  get(role?: any, id?: string) {
    let params = new HttpParams();
    if (id) {
      params = params.append('firebaseUid', id);
    }
    if (role) {
      params = params.append('role', role);
    }
    return this.http.get<User[]>(`${$REST_URI}/user`, { params });
  }

  getAuthors() {
    return this.http.get<any[]>(`${$REST_URI}/question/author`);
  }

  getBiblistUsers(query?: any, download?: boolean) {

    let params = new HttpParams();

    if (query) {
      Object.keys(query).forEach(key => {
        if (key === 'sort') {
          let sortKey = Object.keys(query[key])[0];
          params = params.append(sortKey, query[key][sortKey]);
        } else {
          if (query[key]) {
            if (typeof (query[key]) === 'string' || (query[key] instanceof Date) || typeof (query[key]) === 'number' || typeof (query[key]) === 'boolean') {
              params = params.append(key, query[key]);
            } else {
              if (query[key].length > 0) {
                params = params.append(key, query[key]);
              }
            }
          }
        }
      });
    } else {
      params = params.append('disablePagination', 'true');
    }

    if (download) {
      params = params.append('isDownload', 'true');
    }

    return this.http.get<any>(`${$REST_URI}/user/biblist`, { params });
  }

  getBiblistHistory(id?: string, level?: any, week?: string) {
    let params = new HttpParams();
    if (id) {
      params = params.append('firebaseUid', id);
    }
    if (level) {
      params = params.append('level', level);
    }
    if (week) {
      params = params.append('week', week);
    }
    return this.http.get<any>(`${$REST_URI}/usersquestion/getQuestionHistory`, { params });
  }

  getBiblistAnalysis(query: any) {
    let params = new HttpParams();
    if (query) {
      // Object.keys(query).forEach(key => {
      //   params = params.append(key, query[key]);
      // });
      Object.keys(query).forEach(key => {
        if (key === 'filterData') {
          Object.keys(query[key]).forEach(key1 => {
            if (query[key][key1] !== null) {
              if (key1 === 'tag') {
                if (query[key][key1] !== "All") {
                  params = params.append(key1, query[key][key1]);
                }
              } else {
                params = params.append(key1, query[key][key1]);
              }
            }
          });
        } else {
          params = params.append(key, query[key]);
        }
      });
    }
    return this.http.get<any>(`${$REST_URI}/usersquestion/getBiblistAnalysis`, { params });
  }

  getBiblistRanking(id?: string, query?: any) {
    let params = new HttpParams();

    // if (query) {
    //   Object.keys(query).forEach(key => {
    //     if (key === 'sort') {
    //       let sortKey = Object.keys(query[key])[0];
    //       params = params.append(sortKey, query[key][sortKey]);
    //     } else {
    //       params = params.append(key, query[key]);
    //     }
    //   });
    // } else {
    //   params = params.append('disablePagination', 'true');
    // }
    if (query) {
      params = params.append('level', query);
    }
    if (id) {
      params = params.append('firebaseUid', id);
    }
    console.log("parms Data", params);
    return this.http.get<any>(`${$REST_URI}/user/biblist/rank`, { params });
  }

  getBiblistActivity(id?: string) {
    let params = new HttpParams();
    if (id) {
      params = params.append('firebaseUid', id);
    }
    return this.http.get<any>(`${$REST_URI}/usersquestion/getBiblistActivity`, { params });
  }

  getCurrentUser() {
    let currentUser = firebase.auth().currentUser;
    if (currentUser) {
      let params = new HttpParams();
      params = params.append('_id', currentUser.uid);
      return this.http.get<User[]>(`${$REST_URI}/user`, { params });
    } else (of(false));
  }

  getNotificationUsers(query?: any, download?: boolean, disablePagination?: boolean) {
    console.log("query", query);
    let params = new HttpParams();

    if (query) {
      Object.keys(query).forEach(key => {
        if (key === 'filterData') {
          Object.keys(query[key]).forEach(key1 => {
            if (query[key][key1] !== null && query[key][key1] !== 0 && !isNaN(query[key][key1])) {
              // if (key1 === 'tag') {
              //   if (query[key][key1] !== "All") {
              //     params = params.append(key1, query[key][key1]);
              //   }
              // } else {
              params = params.append(key1, query[key][key1]);
              // }
            } else if (key1 === 'answeredQuestionCountFrom' || key1 === 'bkpFrom') {
              if (query[key][key1] === 0) {
                params = params.append(key1, query[key][key1]);
              }
            } else {
              params = params.append(key1, query[key][key1]);
            }
          });
        } else {
          params = params.append(key, query[key]);
        }
      });
    } else {
      params = params.append('disablePagination', 'true');
    }

    if (disablePagination) {
      params = params.append('disablePagination', 'true');
    }

    if (download) {
      params = params.append('isDownload', 'true');
    }

    return this.http.get<any>(`${$REST_URI}/user/biblist/notification`, { params });
  }

  getNotificationCondition() {
    return this.http.get<any>(`${$REST_URI}/foundation/condition`);
  }

  edit(data) {
    return this.http.put<User>(`${$REST_URI}/user`, data);
  }

  changePassword(data) {
    return this.http.put(`${$REST_URI}/client/password/change`, data)
  }

  changeRole(data) {
    return this.http.put(`${$REST_URI}/user/role`, data)
  }

  logout(): boolean {
    try {
      this.removeUser();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

}
