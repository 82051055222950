export const $hexLayout = {
    "level-1": {
        "layout": "odd-q",
        // "hexes": {
        //     "Start": { "n": "Start", "q": 0, "r": 0 },
        //     "1": { "n": "1", "q": 0, "r": 1 },
        //     "2": { "n": "2", "q": 1, "r": 1 },
        //     "3": { "n": "3", "q": 1, "r": 0 },
        //     "4": { "n": "4", "q": 0, "r": -1 },
        //     "5": { "n": "5", "q": -1, "r": 0 },
        //     "6": { "n": "6", "q": -1, "r": 1 },
        //     "7": { "n": "7", "q": -1, "r": 2 },
        //     "8": { "n": "8", "q": 0, "r": 2 },
        //     "9": { "n": "9", "q": 1, "r": 2 },
        //     "20": { "n": "20", "q": -1, "r": 3 },
        //     "21": { "n": "21", "q": 0, "r": 3 },
        //     "22": { "n": "22", "q": 1, "r": 3 },
        //     "39": { "n": "39", "q": -1, "r": 4 },
        //     "40": { "n": "40", "q": 0, "r": 4 },
        //     "41": { "n": "41", "q": 1, "r": 4 },
        // },
        "hexes": {
            "1": { "n": "1", "q": 0, "r": 0 },
            "8": { "n": "8", "q": 1, "r": 0 },
            "2": { "n": "2", "q": 2, "r": 0},
            "9": { "n": "9", "q": 3, "r": 0 },
            "3": { "n": "3", "q": 4, "r": 0 },
            "10": { "n": "10", "q": 5, "r": 0 },
            "4": { "n": "4", "q": 6, "r": 0 },
            "11": { "n": "11", "q": 7, "r": 0 },
            "5": { "n": "5", "q": 8, "r": 0 },
            "12": { "n": "12", "q": 9, "r": 0 },
            "6": { "n": "6", "q": 10, "r": 0 },
            "13": { "n": "13", "q": 11, "r": 0 },
            "7": { "n": "7", "q": 12, "r": 0 },

            "14": { "n": "14", "q": 0, "r": -1 },
            "21": { "n": "21", "q": 1, "r": -1 },
            "15": { "n": "15", "q": 2, "r": -1 },
            "22": { "n": "22", "q": 3, "r": -1 },
            "16": { "n": "16", "q": 4, "r": -1 },
            "23": { "n": "23", "q": 5, "r": -1 },
            "17": { "n": "17", "q": 6, "r": -1 },
            "24": { "n": "24", "q": 7, "r": -1 },
            "18": { "n": "18", "q": 8, "r": -1 },
            "25": { "n": "25", "q": 9, "r": -1 },
            "19": { "n": "19", "q": 10, "r": -1 },
            "26": { "n": "26", "q": 11, "r": -1 },
            "20": { "n": "20", "q": 12, "r": -1 },

            "27": { "n": "27", "q": 0, "r": -2 },
            "34": { "n": "34", "q": 1, "r": -2 },
            "28": { "n": "28", "q": 2, "r": -2 },
            "35": { "n": "35", "q": 3, "r": -2 },
            "29": { "n": "29", "q": 4, "r": -2 },
            "36": { "n": "36", "q": 5, "r": -2 },
            "30": { "n": "30", "q": 6, "r": -2 },
            "37": { "n": "37", "q": 7, "r": -2 },
            "31": { "n": "31", "q": 8, "r": -2 },
            "38": { "n": "38", "q": 9, "r": -2 },
            "32": { "n": "32", "q": 10, "r": -2 },
            "39": { "n": "39", "q": 11, "r": -2 },
            "33": { "n": "33", "q": 12, "r": -2 },
            
            "40": { "n": "40", "q": 0, "r": -3 },
            "47": { "n": "47", "q": 1, "r": -3 },
            "41": { "n": "41", "q": 2, "r": -3 },
            "48": { "n": "48", "q": 3, "r": -3 },
            "42": { "n": "42", "q": 4, "r": -3 },
            "49": { "n": "49", "q": 5, "r": -3 },
            "43": { "n": "43", "q": 6, "r": -3 },
            "50": { "n": "50", "q": 7, "r": -3 },
            "44": { "n": "44", "q": 8, "r": -3 },
            "51": { "n": "51", "q": 9, "r": -3 },
            "45": { "n": "45", "q": 10, "r": -3 },
            "52": { "n": "52", "q": 11, "r": -3 },
            "46": { "n": "46", "q": 12, "r": -3 },
        
            "53": { "n": "53", "q": 0, "r": -4 },
            "60": { "n": "60", "q": 1, "r": -4 },
            "54": { "n": "54", "q": 2, "r": -4 },
            "61": { "n": "61", "q": 3, "r": -4 },
            "55": { "n": "55", "q": 4, "r": -4 },
            "62": { "n": "62", "q": 5, "r": -4 },
            "56": { "n": "56", "q": 6, "r": -4 },
            "63": { "n": "63", "q": 7, "r": -4 },
            "57": { "n": "57", "q": 8, "r": -4 },
            "64": { "n": "64", "q": 9, "r": -4 },
            "58": { "n": "58", "q": 10, "r": -4 },
            "65": { "n": "65", "q": 11, "r": -4 },
            "59": { "n": "59", "q": 12, "r": -4 },

            "66": { "n": "66", "q": 0, "r": -5 },
            "73": { "n": "73", "q": 1, "r": -5 },
            "67": { "n": "67", "q": 2, "r": -5 },
            "74": { "n": "74", "q": 3, "r": -5 },
            "68": { "n": "68", "q": 4, "r": -5 },
            "75": { "n": "75", "q": 5, "r": -5 },
            "69": { "n": "69", "q": 6, "r": -5 },
            "76": { "n": "76", "q": 7, "r": -5 },
            "70": { "n": "70", "q": 8, "r": -5 },
            "77": { "n": "77", "q": 9, "r": -5 },
            "71": { "n": "71", "q": 10, "r": -5 },
            "78": { "n": "78", "q": 11, "r": -5 },
            "72": { "n": "72", "q": 12, "r": -5 },

            "79": { "n": "79", "q": 0, "r": -6 },
            "86": { "n": "86", "q": 1, "r": -6 },
            "80": { "n": "80", "q": 2, "r": -6 },
            "87": { "n": "87", "q": 3, "r": -6 },
            "81": { "n": "81", "q": 4, "r": -6 },
            "88": { "n": "88", "q": 5, "r": -6 },
            "82": { "n": "82", "q": 6, "r": -6 },
            "89": { "n": "89", "q": 7, "r": -6 },
            "83": { "n": "83", "q": 8, "r": -6 },
            "90": { "n": "90", "q": 9, "r": -6 },
            "84": { "n": "84", "q": 10, "r": -6 },
            "91": { "n": "91", "q": 11, "r": -6 },
            "85": { "n": "85", "q": 12, "r": -6 },

            "92": { "n": "92", "q": 0, "r": -7 },
            "99": { "n": "99", "q": 1, "r": -7 },
            "93": { "n": "93", "q": 2, "r": -7 },
            "100": { "n": "100", "q": 3, "r": -7 },
            "94": { "n": "94", "q": 4, "r": -7 },
            "101": { "n": "101", "q": 5, "r": -7 },
            "95": { "n": "95", "q": 6, "r": -7 },
            "102": { "n": "102", "q": 7, "r": -7 },
            "96": { "n": "96", "q": 8, "r": -7 },
            "103": { "n": "103", "q": 9, "r": -7 },
            "97": { "n": "97", "q": 10, "r": -7 },
            "104": { "n": "104", "q": 11, "r": -7 },
            "98": { "n": "98", "q": 12, "r": -7 },

            "105": { "n": "105", "q": 0, "r": -8 },
            "112": { "n": "112", "q": 1, "r": -8 },
            "106": { "n": "106", "q": 2, "r": -8 },
            "113": { "n": "113", "q": 3, "r": -8 },
            "107": { "n": "107", "q": 4, "r": -8 },
            "114": { "n": "114", "q": 5, "r": -8 },
            "108": { "n": "108", "q": 6, "r": -8 },
            "115": { "n": "115", "q": 7, "r": -8 },
            "109": { "n": "109", "q": 8, "r": -8 },
            "116": { "n": "116", "q": 9, "r": -8 },
            "110": { "n": "110", "q": 10, "r": -8 },
            "117": { "n": "117", "q": 11, "r": -8 },
            "111": { "n": "111", "q": 12, "r": -8 },

            "118": { "n": "118", "q": 0, "r": -9 },
            "125": { "n": "125", "q": 1, "r": -9 },
            "119": { "n": "119", "q": 2, "r": -9 },
            "126": { "n": "126", "q": 3, "r": -9 },
            "120": { "n": "120", "q": 4, "r": -9 },
            "127": { "n": "127", "q": 5, "r": -9 },
            "121": { "n": "121", "q": 6, "r": -9 },
            "128": { "n": "128", "q": 7, "r": -9 },
            "122": { "n": "122", "q": 8, "r": -9 },
            "129": { "n": "129", "q": 9, "r": -9 },
            "123": { "n": "123", "q": 10, "r": -9 },
            "130": { "n": "130", "q": 11, "r": -9 },
            "124": { "n": "124", "q": 12, "r": -9 },

            "131": { "n": "131", "q": 0, "r": -10 },
            "138": { "n": "138", "q": 1, "r": -10 },
            "132": { "n": "132", "q": 2, "r": -10 },
            "139": { "n": "139", "q": 3, "r": -10 },
            "133": { "n": "133", "q": 4, "r": -10 },
            "140": { "n": "140", "q": 5, "r": -10 },
            "134": { "n": "134", "q": 6, "r": -10 },
            "141": { "n": "141", "q": 7, "r": -10 },
            "135": { "n": "135", "q": 8, "r": -10 },
            "142": { "n": "142", "q": 9, "r": -10 },
            "136": { "n": "136", "q": 10, "r": -10 },
            "143": { "n": "143", "q": 11, "r": -10 },
            "137": { "n": "137", "q": 12, "r": -10 },
            
            "144": { "n": "144", "q": 0, "r": -11 },
            "151": { "n": "151", "q": 1, "r": -11 },
            "145": { "n": "145", "q": 2, "r": -11 },
            "152": { "n": "152", "q": 3, "r": -11 },
            "146": { "n": "146", "q": 4, "r": -11 },
            "153": { "n": "153", "q": 5, "r": -11 },
            "147": { "n": "147", "q": 6, "r": -11 },
            "154": { "n": "154", "q": 7, "r": -11 },
            "148": { "n": "148", "q": 8, "r": -11 },
            "155": { "n": "155", "q": 9, "r": -11 },
            "149": { "n": "149", "q": 10, "r": -11 },
            "156": { "n": "156", "q": 11, "r": -11 },
            "150": { "n": "150", "q": 12, "r": -11 },

            "157": { "n": "157", "q": 0, "r": -12 },
            "164": { "n": "164", "q": 1, "r": -12 },
            "158": { "n": "158", "q": 2, "r": -12 },
            "165": { "n": "165", "q": 3, "r": -12 },
            "159": { "n": "159", "q": 4, "r": -12 },
            "166": { "n": "166", "q": 5, "r": -12 },
            "160": { "n": "160", "q": 6, "r": -12 },
            "167": { "n": "167", "q": 7, "r": -12 },
            "161": { "n": "161", "q": 8, "r": -12 },
            "168": { "n": "168", "q": 9, "r": -12 },
            "162": { "n": "162", "q": 10, "r": -12 },
            "169": { "n": "169", "q": 11, "r": -12 },
            "163": { "n": "163", "q": 12, "r": -12 },

            "170": { "n": "170", "q": 0, "r": -13 },
            "177": { "n": "177", "q": 1, "r": -13 },
            "171": { "n": "171", "q": 2, "r": -13 },
            "178": { "n": "178", "q": 3, "r": -13 },
            "172": { "n": "172", "q": 4, "r": -13 },
            "179": { "n": "179", "q": 5, "r": -13 },
            "173": { "n": "173", "q": 6, "r": -13 },
            "180": { "n": "180", "q": 7, "r": -13 },
            "174": { "n": "174", "q": 8, "r": -13 },
            "181": { "n": "181", "q": 9, "r": -13 },
            "175": { "n": "175", "q": 10, "r": -13 },
            "182": { "n": "182", "q": 11, "r": -13 },
            "176": { "n": "176", "q": 12, "r": -13 },

            "183": { "n": "183", "q": 0, "r": -14 },
            "184": { "n": "184", "q": 2, "r": -14 },
            "185": { "n": "185", "q": 4, "r": -14 },
            "186": { "n": "186", "q": 6, "r": -14 },
            "187": { "n": "187", "q": 8, "r": -14 },
            "188": { "n": "188", "q": 10, "r": -14 },
            "189": { "n": "189", "q": 12, "r": -14 },
        },
    }
}