import { $DOMAIN, $REST_URI } from 'src/environments/environment';

export function tokenGetter() {
    return localStorage.getItem('auth-token');
}

export function jwtOptionsFactory(authService) {
    return {
        tokenGetter: () => {
            return authService.getToken();
        },
        headerName: 'x-auth-token',
        authScheme: '',
        allowedDomains: [`${$DOMAIN}`, 'e928896ae7fb.ngrok.io'],
        disallowedRoutes: [$REST_URI + '/location', $REST_URI + '/user/login'],
    }
}