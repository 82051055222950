import { Injectable, NgZone } from '@angular/core';
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import * as firebase from 'firebase/app';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { $REST_URI } from 'src/environments/environment';
import { User } from '../../model/user.model';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private eventAuthError = new BehaviorSubject<string>("");
  eventAuthError$ = this.eventAuthError.asObservable();

  constructor(
    public fireAuth: AngularFireAuth,
    public router: Router,
    private zone: NgZone,
    private http: HttpClient,
  ) { }

  registerUsingGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();

    return new Promise<firebase.auth.UserCredential>((resolve, reject) => {
      this.zone.run(() => {
        firebase.auth().signInWithPopup(provider).then(result => {
          resolve(result);
        }).catch(err => { reject(err); })
      });
    }).then((credential) => {
      if (credential.additionalUserInfo.isNewUser) {
        return this.saveBiblistUser({ name: credential.user.displayName }).then((temp) => {
          console.log(temp);
          return this.getUser(credential);
        });
      } else {
        return this.getUser(credential);
      }
    });
  }

  login(email: string, password: string) {
    return this.fireAuth.signInWithEmailAndPassword(email, password);
  }

  getUser(credential: firebase.auth.UserCredential) {
    return new Promise<User>((resolve, reject) => {
      let params = new HttpParams();
      params = params.append('firebaseUid', credential.user.uid);
      this.http.get<User[]>(`${$REST_URI}/user`, { params }).subscribe(res => {
        resolve(res[0]);
      }, err => {
        reject(err);
      });
    });
  }

  saveBiblistUser(user: any) {
    return new Promise((resolve, reject) => {
      this.http.post(`${$REST_URI}/user/signup/client`, user).subscribe(res => {
        resolve(res);
      }, err => { reject(err); });
    });
  }

  registerUsingFacebook() {
    const provider = new firebase.auth.FacebookAuthProvider();
    return new Promise<firebase.auth.UserCredential>((resolve, reject) => {
      this.zone.run(() => {
        firebase.auth().signInWithPopup(provider).then(result => {
          resolve(result);
        }).catch(err => { reject(err); })
      });
    }).then((credential) => {
      if (credential.additionalUserInfo.isNewUser) {
        return this.saveBiblistUser({ name: credential.user.displayName }).then((temp) => {
          console.log(temp);
          return this.getUser(credential);
        });
      } else {
        return this.getUser(credential);
      }
    });
  }

  signUp(data, token: string) {

    let header = new HttpHeaders();
    header = header.append('x-auth-token', token);

    return this.http.post(`${$REST_URI}/user/signup`, data, { headers: header });
  }

  getToken() {
    return new Promise((resolve, reject) => {
      this.fireAuth.idToken.subscribe(token => {
        console.log("Token:",token);
        resolve(token);
      })
    });
  }

  changePassword(data: any) {
    const currentUser = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(currentUser.email, data.password);

    return currentUser.reauthenticateWithCredential(credential).then((res) => {
      return currentUser.updatePassword(data.newPassword)
    })
  }

  inviteUser(data: any) {
    return this.http.post(`${$REST_URI}/user/invite`, data);
  }
}