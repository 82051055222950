import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorDirective } from './error/error.directive';
import { HandleImgErrorDirective, HandleAvatarErrorDirective } from './handle-img-error/handle-img-error.directive';



@NgModule({
  declarations: [
    ErrorDirective,
    HandleImgErrorDirective,
    HandleAvatarErrorDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ErrorDirective,
    HandleImgErrorDirective,
    HandleAvatarErrorDirective
  ]
})
export class DirectivesModule { }
