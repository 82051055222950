export const $iconPath: string = '/assets/icons'
export const $imagePath: string = '/assets/images'
export const $icons: string[] = [
 'dashboard',   
 'game',   
 'group',   
 'question',   
 'logout',   
 'report',   
 'translate',   
 'user',  
 'approval',
 'question_rounded',
 'options',
 'analytics',
 'question_round_edge',
 'book',
 'preview',
 'filter',
 'PERSON',
 'ITEM',
 'ORDER',
 'LOCATION',
 'REASON',
 'REFERENCE',
 'prev',
 'next',
 'google',
 'twitter',
 'facebook',
 'BIBLE',
 'EXTRA_BIBILICAL',
 'view_result',
 'tag',
 'biblist-User',
 'setting',
 'help',
 'instruction',
 'text',
 'notification'
]