import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppCommonModule } from '../../shared/app-common/app-common.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '../../directives/directives.module';
import { DuplicateMapComponent } from './duplicate-map.component';


@NgModule({
  declarations: [DuplicateMapComponent],
  imports: [
    CommonModule,
    AppCommonModule,
    // BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    DirectivesModule,
  ],
  exports: [
    DuplicateMapComponent
  ],
  entryComponents: [
    DuplicateMapComponent
  ],
})
export class DuplicateMapModule { }
