import { ValidatorFn, AbstractControl } from '@angular/forms';
import { SpecificationCategory, QuestionOption } from '../model/question.model';

export const $validators = {
    stringList,
    listMinLength,
    listMaxLength,
    customError,
    password,
    confirmPassword,
    contactNumber,
    questionOption,
    questionAndOptions,
    questionCategory,
    questionWrongOption
};

/**
 * @description Checks if the value i.e., string array, contains form control value.
 * @param value 
 */
function stringList(value: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const notFound = !value.includes(control.value);
        return notFound ? { 'stringList': { expected: value, input: control.value } } : null;
    };
}

/**
 * @description Checks if form control value is a list and checks min length.
 * @param value
 */
function listMinLength(value: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const result: boolean = typeof control.value == "object" && control.value && (control.value as string[]).length >= value;
        return result ? null : { 'listMinLength': { expectedListLength: value, input: control.value } };
    };
}

/**
 * @description Checks if form control value is a list and checks max length.
 * @param value
 */
function listMaxLength(value: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const result: boolean = typeof control.value == "object" && control.value && (control.value as string[]).length <= value;
        return result ? null : { 'listmaxLength': { expectedListLength: value, input: control.value } };
    };
}

/**
 * @description Sets a custom error with custom error message to the form control.
 * @param message 
 * @param error 
 */
function customError(message: string, error: boolean = true): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        return error ? { 'customError': { message, input: control.value } } : null;
    };
}

/**
 * @description Checks for password strength.
 */
function password(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        // const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        const pattern = /^(?=.*?[A-Z])(?=.*?[a-z]).{6,}$/;
        const result: boolean = pattern.test(control.value);
        return result ? null : { 'password': { expectedPattern: pattern, input: control.value } };
    };
}

/**
 * @description Checks for password strength.
 */
function confirmPassword(value: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const result: boolean = (value === control.value);
        return result ? null : { 'confirmPassword': { expectedPattern: value, input: control.value } };
    };
}

/**
 * @description Checks given value is contact number.
 */
function contactNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const pattern = /^\d{8,12}$/; // /^[0-9].{8,12}$/
        const result: boolean = pattern.test(control.value);
        return result ? null : { 'contactNumber': { expectedPattern: pattern, input: control.value } };
    };
}

/**
 * @description Checks for password strength.
 */
function questionOption(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        let option = control.value as QuestionOption;
        let result: boolean = true;
        if (option.value === 'CORRECT') {
            result = (!!option.specificationCategories.find(ele => ele.type === 'REFERENCE'));
        }
        return result ? null : { 'questionOption': { expectedPattern: '', input: control.value } };
    };
}

/**
 * @description Checks for password strength.
 */
function questionAndOptions(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        let result: boolean =
            control.get('text').valid &&
            control.get('option1').valid &&
            control.get('option2').valid &&
            control.get('option3').valid &&
            control.get('option4').valid &&
            control.get('option5').valid &&
            control.get('option6').valid;
        return result ? null : { 'questionAndOptions': { input: control.value } };
    };
}

/**
 * @description Checks if form control value is a list and checks min length.
 * @param value
 */
function questionCategory(value: any): ValidatorFn {
    console.log("Validator Value", value);
    let tagCheck = ["PERSON", "ITEM", "ORDER", "LOCATION", "REASON", "BIBLE"];
    let tagContain: boolean = false;

    return (control: AbstractControl): { [key: string]: any } | null => {
        control.value.forEach(element => {
            tagCheck.forEach(ele => {
                if(ele === element) {
                    tagContain = true;
                }
            })
        });
        const result: boolean = tagContain;
        tagContain = false;
        return result ? null : { 'questionCategory': { input: control.value } };
    };
}
/**
 * @description Checks for password strength.
 */
function questionWrongOption(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        let option = control.value as QuestionOption;
        let result: boolean = true;
        if (option.value === 'WRONG') {
            result = (!!option.specificationCategories.find(ele => ele.type === 'REFERENCE'));
        }
        return result ? null : { 'questionWrongOption': { expectedPattern: '', input: control.value } };
    };
}