import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackBarService } from '../../services/snackbar/snack-bar.service';
import { VirusFreeService } from '../../services/virus-free/virus-free.service';
import { $validators } from '../../values/validators.values';
import { TagService } from '../../services/tag/tag.service';
import { DataService } from '../../services/data/data.service';
import { $APP_VALUES } from '../../values/app.values';

@Component({
  selector: 'app-add-virusfree-level',
  templateUrl: './add-virusfree-level.component.html',
  styleUrls: ['./add-virusfree-level.component.scss']
})
export class AddVirusfreeLevelComponent implements OnInit {

  form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    textType: new FormControl('', [Validators.required]),
    levelCopy: new FormControl(''),
  });

  mapDetails: any;

  levels: [];

  createBtnDis: boolean = false;

  textTypes: any[] = $APP_VALUES.textTypes;

  alltextData: any[] = [];
  textData: any[] = [];

  constructor(
    private virusFreeService: VirusFreeService,
    private snackbarService: SnackBarService,
    private tagService: TagService,
    private dataService: DataService,
    public dialogRef: MatDialogRef<AddVirusfreeLevelComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.mapDetails = data.map;
    console.log(this.mapDetails);
    this.levels = this.mapDetails.levels;
  }

  create() {
    console.log("Create level");
    console.log("this.form", this.form);
    console.log("this.mapDetails", this.mapDetails);

    if (this.form.valid) {
      this.snackbarService.loader();
      this.createBtnDis = true;
      let haveLevel;
      console.log(this.levels);
      console.log(this.form.get('name').value);
      if (this.levels) {
        this.levels.forEach(ele => {
          if (ele === this.form.get('name').value) {
            haveLevel = true;
          }
        })
      }
      setTimeout(() => {
        console.log(haveLevel);
        if (haveLevel === true) {
          this.snackbarService.info("Level already exists");
          this.createBtnDis = false;
        } else {
          let data = { _id: this.mapDetails._id, level: this.form.get('name').value }
          this.virusFreeService.updateVirusFreeMaps(data).subscribe(res1 => {
            this.snackbarService.info("Level created successfully");
            if (this.form.get('levelCopy').value) {
              let data = { language: "ENGLISH", mapId: this.mapDetails._id, level: this.form.get('levelCopy').value }
              this.virusFreeService.getMapCell(data).subscribe(res2 => {
                console.log("res", res2);
                if (res2[0]) {
                  this.snackbarService.loader();
                  let data = { mazeId: res2[0]._id, level: this.form.get('name').value };
                  this.virusFreeService.copyFromLevel(data).subscribe(res3 => {
                    console.log("copy from level response", res3);
                    this.snackbarService.info("level copied");
                    this.createBtnDis = false;
                    this.dialogRef.close(res1);
                  }, err => {
                    console.log(err);
                    this.snackbarService.error(err.error);
                    this.createBtnDis = false;
                    this.dialogRef.close(res1);
                  })
                } else {
                  this.createBtnDis = false;
                  this.dialogRef.close(res1);
                }
              });
            } else {
              this.createBtnDis = false;
              this.dialogRef.close(res1);
            }
          }, err => {
            this.createBtnDis = false;
            this.snackbarService.error(err.error);
            console.log(err.error);
          })
        }
      }, 500);
    }
  }

  displayFn(data: string): string {
    console.log(data);
    return this.alltextData?.find((ele) => ele.parentId === data)?.text || '';
  }

  displayFnTag(data: string): string {
    console.log(data);
    return this.alltextData?.find((ele) => ele.parentId === data)?.value || '';
  }

  getAllText() {
    // console.log("lan",this.languages);
    this.alltextData = [];
    let filterData = { language: this.mapDetails.map.language, disablePagination: true }
    this.dataService.getText(filterData).subscribe(res => {
      console.log("All Text", res);
      this.alltextData = res.texts;
      this.textData = res.texts;
    }, err => {
      this.snackbarService.error(err.error);
    });
  }

  getAllTag() {
    // console.log("lan",this.languages);
    this.alltextData = [];
    let filterData = { language: "ENGLISH" }
    this.tagService.get(filterData).subscribe(res => {
      console.log("All Tag Text", res);
      this.alltextData = res.specificationTagsList;
      this.textData = res.specificationTagsList;
    }, err => {
      this.snackbarService.error(err.error);
    });
  }

  validateTextCheck() {
    let control = this.form.get('name');
    console.log(control);
    let found = this.alltextData.find(ele => ele.parentId === control.value);

    if (!found) {
      control.setValidators([Validators.required, $validators.customError('Name not found', true)]);
    } else {
      control.setValidators([Validators.required]);
    }
    control.updateValueAndValidity({ emitEvent: false });
  }

  ngOnInit(): void {
    console.log('mapDetails', this.mapDetails);
    this.form.get('textType').setValue('TEXT');
    this.form.get('textType').updateValueAndValidity();

    this.getAllText();
    // this.getAllTag();

    this.form.get('name').valueChanges.subscribe(res => {
      // console.log("res", res);
      if (res) {
        const filterValue = res.toLowerCase() || '';

        this.textData = this.alltextData?.filter(option => (option?.parentId.toLowerCase().includes(filterValue) || option?.text?.toLowerCase().includes(filterValue) || option?.value?.toLowerCase().includes(filterValue) || option?.uniqueId.toLowerCase().includes(filterValue)));
      } else {
        this.textData = this.alltextData;
      }
      this.validateTextCheck()
    })

    this.form.get('textType').valueChanges.subscribe(data => {
      if (data) {
        if (data === 'TEXT') {
          this.getAllText();
        } else if (data === 'TAG') {
          this.getAllTag();
        }
      }
    });
  }

}
