import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppCommonModule } from '../../shared/app-common/app-common.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddVirusfreeLevelComponent } from './add-virusfree-level.component';
import { DirectivesModule } from '../../directives/directives.module';
import { MatRadioModule } from '@angular/material/radio';


@NgModule({
  declarations: [AddVirusfreeLevelComponent],
  imports: [
    CommonModule,
    AppCommonModule,
    // BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    DirectivesModule,
    MatRadioModule,
  ],
  exports: [
    AddVirusfreeLevelComponent
  ],
  entryComponents: [
    AddVirusfreeLevelComponent
  ],
})
export class AddVirusfreeLevelModule { }