export const $APP_VALUES = {
    errorMessages: {
        default: 'Something went wrong, please try again'
    },
    statusFilterDates: [
        { code: 'WEEK', label: 'A week ago' },
        { code: 'MONTH', label: 'A month ago' },
        { code: 'SIX_MONTHS', label: '6 months ago' },
        { code: 'YEAR', label: 'An year ago' }
    ],
    languages: [{ code: 'ENGLISH', label: 'English', shortcut: 'EN' }, { code: 'GERMAN', label: 'German', shortcut: 'DE' }],
    optionsProperty: [
        { shortName: '1', name: 'option1', label: 'Option A', shortLabel: 'A' },
        { shortName: '2', name: 'option2', label: 'Option B', shortLabel: 'B' },
        { shortName: '3', name: 'option3', label: 'Option C', shortLabel: 'C' },
        { shortName: '4', name: 'option4', label: 'Option D', shortLabel: 'D' },
        { shortName: '5', name: 'option5', label: 'Option E', shortLabel: 'E' },
        { shortName: '6', name: 'option6', label: 'Option F', shortLabel: 'F' },
    ],
    questionStatuses: [
        { name: 'A', label: 'Status A', shortLabel: 'A', class: 'status-a' },
        { name: 'B', label: 'Status B', shortLabel: 'B', class: 'status-b' },
        { name: 'C', label: 'Status C', shortLabel: 'C', class: 'status-c' },
        { name: 'D', label: 'Status D', shortLabel: 'D', class: 'status-d' },
        { name: 'E', label: 'Status E', shortLabel: 'E', class: 'status-e' },
        { name: 'F', label: 'Status F', shortLabel: 'F', class: 'status-f' },
        { name: 'G', label: 'Status G', shortLabel: 'G', class: 'status-g' },

        { name: 'H', label: 'Status H', shortLabel: 'H', class: 'status-h' },
    ],
    approvalStatuses: [
        // { name: 'A', label: 'Status A', shortLabel: 'A', class: 'status-a' },
        { name: 'B', label: 'Status B', shortLabel: 'B', class: 'status-b' },
        { name: 'D', label: 'Status D', shortLabel: 'D', class: 'status-d' },
    ],
    levels: [
        { code: 1, label: 'Level 1' },
        { code: 2, label: 'Level 2' },
        { code: 3, label: 'Level 3' },
        { code: 4, label: 'Level 4' },
        { code: 5, label: 'Level 5' },
        { code: 6, label: 'Level 6' },
        // { code: 7, label: 'Level 7' },
    ],
    questionStepperForms: {
        questionText: ['text', 'language', 'specificationTags', 'initialDifficultyLevel', 'questionCategory'],
        questionOption: ['option1', 'option2', 'option3', 'option4', 'option5', 'option6', 'correctAnswerCount'],
        analytics: ['readingTime', 'averageTime', 'thinkingTime', 'cheatingTime', 'isSubmittableWithoutAnswer', 'isCatchy', 'isEasyToFind', 'applicability'],
        relatives: [],
        practice: ['surveyQuestion']
    },
    questionsCount: [[
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false}
      ], [
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false}
      ], [
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false}
      ], [
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false}
      ], [
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false}
      ], [
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false}
      ], [
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false},
        {questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false}
      ]],

      textTypes: [
        {
          name: 'Text',
          value: 'TEXT'
        },
        {
          name: 'Tag',
          value: 'TAG'
        },
      ],

    // Functions or Callbacks
    filterPredicate
};


function filterPredicate(data: any, filter: string) {
    let temp: { val: string[], column: string }[] = JSON.parse(filter);
    let found = true;
    for (let i = 0; i < temp.length; i++) {
        // console.log(data[temp[i].column].toString(), temp[i].val);
        found = !!temp[i].val.find(ele => data[temp[i].column].toString().indexOf(ele) > -1);
        // console.log(found)
        if (!found) break;
    }
    return found;
}

export interface QuestionOptionProperty {
    shortName: string,
    name: string,
    label: string,
    shortLabel: string,
}