import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { $REST_URI } from 'src/environments/environment';
import { $hexLayout } from '../../values/hexLayout.value';

@Injectable({
  providedIn: 'root'
})
export class VirusFreeService {

  constructor(
    private http: HttpClient
  ) { }

  saveVirusFreeMaps(map: any) {
    return this.http.post<any>(`${$REST_URI}/game/virusfree/virusfreemap`, map);
  }

  updateVirusFreeMaps(map: any) {
    return this.http.put<any>(`${$REST_URI}/game/virusfree/virusfreemap`, map);
  }

  getAllVirusFreeMaps() {
    return this.http.get<any>(`${$REST_URI}/game/virusfree/virusfreemap`);
  }

  getAllVirusFreeMapsFilter(query: any) {
    let params = new HttpParams();

    if (query) {
      Object.keys(query).forEach(key => {
        if (key === 'sort') {
          let sortKey = Object.keys(query[key])[0];
          params = params.append(sortKey, query[key][sortKey]);
        } else {
          params = params.append(key, query[key]);
        }
      });
    }
    return this.http.get<any>(`${$REST_URI}/game/virusfree/virusfreemap`, { params });
  }

  getVirusFreeMap(id: any) {
    return this.http.get<any>(`${$REST_URI}/game/virusfree/virusfreemap/${id}`);
  }

  saveMapCell(cell: any) {
    return this.http.post<any>(`${$REST_URI}/game/virusfree/virusfreemaze`, cell);
  }

  saveMapLevel(cells: any) {
    return this.http.put<any>(`${$REST_URI}/game/virusfree/virusfreemaze/activate`, cells);
  }

  deleteMapLevel(levelInfo: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: levelInfo
    }
    
    return this.http.delete<any>(`${$REST_URI}/game/virusfree/virusfreemaze`, options);
  }

  deleteMapCell(cell: any) {
    return this.http.put<any>(`${$REST_URI}/game/virusfree/virusfreemaze/deleteCell`, cell);
  }

  deleteMap(map: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: map
    }
    
    return this.http.delete<any>(`${$REST_URI}/game/virusfree/virusfreemap`, options);
  }

  getMapCell(query: any) {
    let params = new HttpParams();

    if (query) {
      Object.keys(query).forEach(key => {
        if (query[key]) {
          params = params.append(key, query[key]);
        }
      });
    }

    return this.http.get<any>(`${$REST_URI}/game/virusfree/virusfreemaze`, { params });
  }

  getVirusfreeleaderboard(query: any) {
    let params = new HttpParams();

    if (query) {
      Object.keys(query).forEach(key => {
        if (key === 'sort') {
          let sortKey = Object.keys(query[key])[0];
          params = params.append(sortKey, query[key][sortKey]);
        } else {
          params = params.append(key, query[key]);
        }
      });
    }
    return this.http.get<any>(`${$REST_URI}/game/virusfree/virusfreeleaderboard/admin`, { params });
  }

  getQuestionById(query: any) {
    let params = new HttpParams();

    if (query) {
      Object.keys(query).forEach(key => {
        if (query[key]) {
          params = params.append(key, query[key]);
        }
      });
    }

    return this.http.get<any>(`${$REST_URI}/question/virusFree`, { params });
  }

  getAllLeaderBoard(query: any) {

    let params = new HttpParams();

    if (query) {
      Object.keys(query).forEach(key => {
        if (query[key]) {
          params = params.append(key, query[key]);
        }
      });
    }

    return this.http.get<any>(`${$REST_URI}/game/virusfree/virusfreeleaderboard`, { params });
  }

  copyFromLevel(levelDetails: any) {
    return this.http.post<any>(`${$REST_URI}/game/virusfree/virusfreemaze/duplicate`, levelDetails);
  }

  checkCellQuestion(data: any) {
    return this.http.post<any>(`${$REST_URI}/question/virusFree/availability`, data);
  }

  findA(cellIndex, betweenValue, levelQuestions) {
    let A;
    let number = parseInt(cellIndex);
    let haveIndex;
    let hexLayout: any;
    hexLayout = $hexLayout["level-1"];
    let endCellHex;

    for (let i = number; i > 0; i = i - betweenValue) {
      number = number - betweenValue;
      if (haveIndex !== true) {
        levelQuestions.forEach(question => {
          if (number.toString() === question.index) {
            A = number;
            haveIndex = true;
          }
        })
      }
      let tempHex = hexLayout.hexes[number];
      if (tempHex) {
        if (tempHex.r === 0) {
          break;
        }
      } else {
        break;
      }
    }

    while (number < 190) {
      let tempHex = hexLayout.hexes[number];
      let nextHex = hexLayout.hexes[number + betweenValue];
      if (tempHex) {
        if (tempHex.r === -13) {
          if (!nextHex) {
            endCellHex = tempHex.n;
            break;
          }
        } else if (tempHex.r === -14) {
          endCellHex = tempHex.n;
          break;
        } else if (!nextHex) {
          endCellHex = tempHex.n;
          break;
        }
      }
      number = number + betweenValue;
    }

    if (A == undefined) {
      while (endCellHex > 0) {
        if (haveIndex !== true) {
          levelQuestions.forEach(question => {
            if (number.toString() === question.index) {
              A = number;
              haveIndex = true;
            }
          })
        }
        let tempHex = hexLayout.hexes[number];
        if (tempHex) {
          if (tempHex.r === 0) {
            break;
          }
        }
        number = number - parseInt(betweenValue);
      }
    }

    if (A == undefined) {
      A = cellIndex;
    }

    return A.toString();
  }

  findB(cellIndex, betweenValue, levelQuestions) {
    let A;
    let number = parseInt(cellIndex);
    let haveIndex;
    let hexLayout: any;
    hexLayout = $hexLayout["level-1"];
    let endCellHex;

    for (let i = number; i > 0; i = i - betweenValue) {
      number = number - betweenValue;
      if (haveIndex !== true) {
        levelQuestions.forEach(question => {
          if (number.toString() === question.index) {
            A = number;
            haveIndex = true;
          }
        })
      }
      let tempHex = hexLayout.hexes[number];
      let nextHex = hexLayout.hexes[number - betweenValue];
      if (tempHex) {
        if (tempHex.q === 12) {
          break;
        } else if (!nextHex) {
          break;
        }
      } else {
        break;
      }
    }

    while (number < 190) {
      let tempHex = hexLayout.hexes[number];
      let nextHex = hexLayout.hexes[number + betweenValue];
      if (tempHex) {
        if (tempHex.q === 0) {
          endCellHex = tempHex.n;
          break;
        } else if (!nextHex) {
          endCellHex = tempHex.n;
          break;
        }
      }
      number = number + betweenValue;
    }

    if (A == undefined) {
      while (endCellHex > 0) {
        if (haveIndex !== true) {
          levelQuestions.forEach(question => {
            if (endCellHex.toString() === question.index) {
              A = endCellHex;
              haveIndex = true;
            }
          })
        }
        let tempHex = hexLayout.hexes[endCellHex];
        let nextHex = hexLayout.hexes[endCellHex - betweenValue];
        if (tempHex) {
          if (tempHex.q === 12) {
            break;
          } else if (!nextHex) {
            break;
          }
        }
        endCellHex = parseInt(endCellHex) - parseInt(betweenValue);
      }
    }

    if (A == undefined) {
      A = cellIndex;
    }

    return A.toString();
  }

  findC(cellIndex, betweenValue, levelQuestions) {
    let A;
    let number = parseInt(cellIndex);
    let haveIndex;
    let hexLayout: any;
    hexLayout = $hexLayout["level-1"];
    let endCellHex;

    for (let i = number; i < 190; i = i + betweenValue) {
      number = number + betweenValue;
      if (haveIndex !== true) {
        levelQuestions.forEach(question => {
          if (number.toString() === question.index) {
            A = number;
            haveIndex = true;
          }
        })
      }
      let tempHex = hexLayout.hexes[number];
      let nextHex = hexLayout.hexes[number + betweenValue];
      if (tempHex) {
        if (tempHex.q === 12) {
          break;
        } else if (!nextHex) {
          break;
        }
      } else {
        break;
      }
    }

    number = parseInt(cellIndex);
    while (number > 0) {
      let tempHex = hexLayout.hexes[number];
      let nextHex = hexLayout.hexes[number - betweenValue];
      if (tempHex) {
        if (tempHex.q === 0) {
          endCellHex = tempHex.n;
          break;
        } else if (!nextHex) {
          endCellHex = tempHex.n;
          break;
        }
      }
      number = number - betweenValue;
    }
    if (A == undefined) {
      while (endCellHex < 190) {
        if (haveIndex !== true) {
          levelQuestions.forEach(question => {
            if (endCellHex.toString() === question.index) {
              A = endCellHex;
              haveIndex = true;
            }
          })
        }
        let tempHex = hexLayout.hexes[parseInt(endCellHex)];
        let nextHex = hexLayout.hexes[parseInt(endCellHex) + parseInt(betweenValue)];
        if (tempHex) {
          if (tempHex.q === 12) {
            break;
          } else if (!nextHex) {
            break;
          }
        }
        endCellHex = parseInt(endCellHex) + parseInt(betweenValue);
      }
    }

    if (A == undefined) {
      A = cellIndex;
    }

    return A.toString();
  }

  findD(cellIndex, betweenValue, levelQuestions) {
    let A;
    let number = parseInt(cellIndex);
    let haveIndex;
    let hexLayout: any;
    hexLayout = $hexLayout["level-1"];
    let endCellHex;

    for (let i = number; i < 190; i = i + betweenValue) {
      number = number + betweenValue;
      if (haveIndex !== true) {
        levelQuestions.forEach(question => {
          if (number.toString() === question.index) {
            A = number;
            haveIndex = true;
          }
        })
      }
      let tempHex = hexLayout.hexes[number];
      let nextHex = hexLayout.hexes[number + betweenValue];
      if (tempHex) {
        if (tempHex.r === -13) {
          if (!nextHex) {
            endCellHex = tempHex.n;
            break;
          }
        } else if (tempHex.r === -14) {
          endCellHex = tempHex.n;
          break;
        } else if (!nextHex) {
          endCellHex = tempHex.n;
          break;
        }
      }
    }

    number = parseInt(cellIndex);
    while (number > 0) {
      let tempHex = hexLayout.hexes[number];
      let nextHex = hexLayout.hexes[number - betweenValue];
      if (tempHex) {
        if (tempHex.r === 0) {
          endCellHex = tempHex.n;
          break;
        } else if (!nextHex) {
          endCellHex = tempHex.n;
          break;
        }
      }
      number = number - betweenValue;
    }

    if (A == undefined) {
      while (endCellHex < 190) {
        if (haveIndex !== true) {
          levelQuestions.forEach(question => {
            if (endCellHex.toString() === question.index) {
              A = endCellHex;
              haveIndex = true;
            }
          })
        }
        let tempHex = hexLayout.hexes[parseInt(endCellHex)];
        let nextHex = hexLayout.hexes[parseInt(endCellHex) + parseInt(betweenValue)];
        if (tempHex) {
          if (tempHex.q === 12) {
            break;
          } else if (!nextHex) {
            break;
          }
        }
        endCellHex = parseInt(endCellHex) + parseInt(betweenValue);
      }
    }

    if (A == undefined) {
      A = cellIndex;
    }

    return A.toString();
  }

  findE(cellIndex, betweenValue, levelQuestions) {
    let A;
    let number = parseInt(cellIndex);
    let haveIndex;
    let hexLayout: any;
    hexLayout = $hexLayout["level-1"];
    let endCellHex;

    for (let i = number; i < 190; i = i + betweenValue) {
      number = number + betweenValue;
      if (haveIndex !== true) {
        levelQuestions.forEach(question => {
          if (number.toString() === question.index) {
            A = number;
            haveIndex = true;
          }
        })
      }
      let tempHex = hexLayout.hexes[number];
      let nextHex = hexLayout.hexes[number + betweenValue];
      if (tempHex) {
        if (tempHex.q === 0) {
          break;
        } else if (!nextHex) {
          break;
        }
      } else {
        break;
      }
    }

    number = parseInt(cellIndex);
    let i = 0;
    while (number > 0) {
      let tempHex = hexLayout.hexes[number];
      let nextHex = hexLayout.hexes[number - betweenValue];
      if (tempHex) {
        if (tempHex.q === 12) {
          endCellHex = tempHex.n;
          break;
        } else if (!nextHex) {
          endCellHex = tempHex.n;
          break;
        }
      }
      number = number - betweenValue;
    }
    if (A == undefined) {
      while (endCellHex < 190) {
        if (haveIndex !== true) {
          levelQuestions.forEach(question => {
            if (endCellHex.toString() === question.index) {
              A = endCellHex;
              haveIndex = true;
            }
          })
        }
        let tempHex = hexLayout.hexes[parseInt(endCellHex)];
        let nextHex = hexLayout.hexes[parseInt(endCellHex) + parseInt(betweenValue)];
        if (tempHex) {
          if (tempHex.q === 0) {
            break;
          } else if(!nextHex) {
            break;
          }
        }
        endCellHex = parseInt(endCellHex) + parseInt(betweenValue);
      }
    }

    if (A == undefined) {
      A = cellIndex;
    }

    return A.toString();
  }

  findF(cellIndex, betweenValue, levelQuestions) {
    let A;
    let number = parseInt(cellIndex);
    let haveIndex;
    let hexLayout: any;
    hexLayout = $hexLayout["level-1"];
    let endCellHex;

    for (let i = number; i < 190; i = i - betweenValue) {
      number = number - betweenValue;
      if (haveIndex !== true) {
        levelQuestions.forEach(question => {
          if (number.toString() === question.index) {
            A = number;
            haveIndex = true;
          }
        })
      }
      let tempHex = hexLayout.hexes[number];
      let nextHex = hexLayout.hexes[number - betweenValue];
      if (tempHex) {
        if (tempHex.q === 0) {
          break;
        } else if(!nextHex) {
          break;
        }
      } else {
        break;
      }
    }

    number = parseInt(cellIndex);
    while (number > 0) {
      let tempHex = hexLayout.hexes[number];
      let nextHex = hexLayout.hexes[number + betweenValue];
      if (tempHex) {
        if (tempHex.q === 12) {
          endCellHex = tempHex.n;
          break;
        } else if (!nextHex) {
          endCellHex = tempHex.n;
          break;
        }
      }
      number = number + betweenValue;
    }
    if (A == undefined) {
      while (endCellHex < 190) {
        if (haveIndex !== true) {
          levelQuestions.forEach(question => {
            if (endCellHex.toString() === question.index) {
              A = endCellHex;
              haveIndex = true;
            }
          })
        }
        let tempHex = hexLayout.hexes[parseInt(endCellHex)];
        let nextHex = hexLayout.hexes[parseInt(endCellHex) - parseInt(betweenValue)]
        if (tempHex) {
          if (tempHex.q === 0) {
            break;
          } else if(!nextHex) {
            break;
          }
        }
        endCellHex = parseInt(endCellHex) - parseInt(betweenValue);
      }
    }

    if (A == undefined) {
      A = cellIndex;
    }

    return A.toString();
  }

  getDashboardMap(query: any) {
    let params = new HttpParams();

    if (query) {
      Object.keys(query).forEach(key => {
        if (query[key]) {
          params = params.append(key, query[key]);
        }
      });
    }

    return this.http.get<any>(`${$REST_URI}/game/virusfree/virusfreemap/chartInfo`, { params });
  }

  duplicateMap(data: any) {
    return this.http.post<any>(`${$REST_URI}/game/virusfree/virusfreemap/duplicate`, data);
  }
}