import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { $REST_URI } from 'src/environments/environment';
import { DataService, Language } from '../../services/data/data.service';
import { SnackBarService } from '../../services/snackbar/snack-bar.service';
import { VirusFreeService } from '../../services/virus-free/virus-free.service';
import { CropperDialogComponent, FileCropperResponse, ImageCropperOptions } from '../image-cropper/cropper-dialog/cropper-dialog.component';
import { $validators } from '../../values/validators.values';
import { TagService } from '../../services/tag/tag.service';
import { $APP_VALUES } from '../../values/app.values';

@Component({
  selector: 'app-add-map-dialog',
  templateUrl: './add-map-dialog.component.html',
  styleUrls: ['./add-map-dialog.component.scss']
})
export class AddMapDialogComponent implements OnInit {

  @ViewChild('tagInput')
  tagInput: ElementRef<HTMLInputElement>;

  form: FormGroup = new FormGroup({
    map: new FormControl('', [Validators.required]),
    // languages: new FormControl('', [Validators.required]),
    // size: new FormControl('', [Validators.required]),
    backgroundImage: new FormControl('', [Validators.required]),
    startDate: new FormControl('', [Validators.required]),
    endDate: new FormControl(null),
    // active: new FormControl(false, [Validators.required]),
    textType: new FormControl('', [Validators.required]),
  })

  loading: boolean = false;

  languages: Language[] = [];

  uploading: boolean = false;

  backgroundImg;

  saveBtn: boolean = false;

  newDate: Date = new Date();

  alltextData: any[] = [];
  textData: any[] = [];

  textTypes: any[] = $APP_VALUES.textTypes;

  get imageUrl() {
    if (this.backgroundImg) {
      let temp = this.backgroundImg.split('/');
      this.backgroundImg = `${$REST_URI}/game/virusfree/virusfreemap/backgroundImage/${temp[temp.length - 1]}`
    }
    return this.backgroundImg ? this.backgroundImg : '';
  }

  constructor(
    private dataService: DataService,
    private tagService: TagService,
    private matDialog: MatDialog,
    private router: Router,
    private virusFreeService: VirusFreeService,
    private snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<AddMapDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private snackBar: SnackBarService,
  ) {
    this.languages = data.languages;
  }

  displayFn(data: string): string {
    console.log(data);
    return this.alltextData?.find((ele) => ele.parentId === data)?.text || '';
  }

  displayFnTag(data: string): string {
    console.log(data);
    return this.alltextData?.find((ele) => ele.parentId === data)?.value || '';
  }

  getAllText() {
    // console.log("lan",this.languages);
    this.alltextData = [];
    let filterData = { language: this.languages[0].name, disablePagination: true }
    this.dataService.getText(filterData).subscribe(res => {
      console.log("All Text", res);
      this.alltextData = res.texts;
      this.textData = res.texts;
    }, err => {
      this.snackBar.error(err.error);
    });
  }

  getAllTag() {
    // console.log("lan",this.languages);
    this.alltextData = [];
    let filterData = { language: "ENGLISH" }
    this.tagService.get(filterData).subscribe(res => {
      console.log("All Tag Text", res);
      this.alltextData = res.specificationTagsList;
      this.textData = res.specificationTagsList;
    }, err => {
      this.snackBar.error(err.error);
    });
  }

  save() {
    console.log("this.form", this.form);
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    // let date = this.form.get('startDate').value;
    // date.setDate(date.getDate() + 1);
    // this.form.get('startDate').setValue(date);
    // this.form.get('startDate').updateValueAndValidity();
    if (this.form.valid) {
      this.saveBtn = true;
      this.snackBarService.loader();
      this.virusFreeService.saveVirusFreeMaps(this.form.getRawValue()).subscribe(res => {
        console.log("save map response", res);
        this.saveBtn = false;
        this.snackBarService.info("Map created successfully");
        this.router.navigate([`/games/virus-free/map/${res._id}`]);
        this.dialogRef.close();
      }, err => {
        console.log(err);
        this.saveBtn = false;
        this.snackBarService.error(err.error);
      })
    }
  }

  cropAndUpload(event: any) {

    const options: ImageCropperOptions = {
      aspectRatio: 9 / 16,
      imageQuality: 70,
      resizeToHeight: 400,
      resizeToWidth: 400
    }

    this.matDialog.open(CropperDialogComponent, {
      width: "360px",
      panelClass: 'app-dialog',
      data: {
        event,
        options: options
      }
    }).afterClosed().subscribe((data: FileCropperResponse) => {
      console.log(data);
      if (data) {
        this.uploading = true;
        this.dataService.uploadBackgroundImg(data.file).subscribe((res: { id: string }) => {
          console.log(res);
          this.uploading = false;
          this.backgroundImg = res.id;
          this.form.get('backgroundImage').setValue(res.id);
          this.form.get('backgroundImage').updateValueAndValidity();
          event.target.value = ''
        }, err => {
          this.snackBarService.error(err.error);
          event.target.value = ''
        });
      } else {
        event.target.value = ''
      }
    });

  }

  validateTextCheck() {
    let control = this.form.get('map');
    console.log(control);
    let found = this.alltextData.find(ele => ele.parentId === control.value);

    if (!found) {
      control.setValidators([Validators.required, $validators.customError('Name not found', true)]);
    } else {
      control.setValidators([Validators.required]);
    }
    control.updateValueAndValidity({ emitEvent: false });
  }

  ngOnInit(): void {
    this.form.get('textType').setValue('TEXT');
    this.form.get('textType').updateValueAndValidity();

    this.getAllText();
    // this.getAllTag();

    this.form.get('map').valueChanges.subscribe(res => {
      // console.log("res", res);
      if (res) {
        const filterValue = res.toLowerCase() || '';

        this.textData = this.alltextData?.filter(option => (option?.parentId.toLowerCase().includes(filterValue) || option?.text?.toLowerCase().includes(filterValue) || option?.value?.toLowerCase().includes(filterValue) || option?.uniqueId.toLowerCase().includes(filterValue)));
      } else {
        this.textData = this.alltextData;
      }
      this.validateTextCheck()
    })

    this.form.get('textType').valueChanges.subscribe(data => {
      if(data) {
        if(data === 'TEXT') {
          this.getAllText();
        } else if(data === 'TAG') {
          this.getAllTag();
        }
      }
    });
  }

}
